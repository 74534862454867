import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import { useMapStore } from '@/stores/map/mapStore';
import { useStore } from 'zustand';
import WhereToBuyBtn from './where-to-buy-btn';
import FormatValue from '@/utils/FormatValue';

interface WhereToBuyBtnProps {
  isMobile: boolean;
  setMapOpen: (value: boolean) => void;
  size?: 'small' | 'default' | undefined;
  title?: string | undefined;
  color?: string | undefined;
}

const WhereToBuyBtnEnhanced: FC<WhereToBuyBtnProps> = ({
  isMobile,
  setMapOpen,
  size,
  title,
  color,
}) => {
  const { data } = useStore(useMapStore, (state) => ({
    data: state.data,
  }));

  const dispatch = data.dispatch;
  const [currIdx, setCurrIdx] = useState(0);
  const [fade, setFade] = useState(true);
  const [useFade, setUseFade] = useState(!!dispatch?.length);
  const [dispatchLength, setDispatchLength] = useState(0);
  const interval = 5000;

  const whereToBuy = () => {
    return (
      <WhereToBuyBtn isMobile={isMobile} setMapOpen={setMapOpen} size={size} />
    );
  };

  const getDistance = (idx: number) => {
    const distance = dispatch?.[idx]?.attributes?.distance || 0,
      unit = dispatch?.[idx]?.attributes?.distance_unit || '';

    return (
      distance < 100 && (
        <>
          {distance.toFixed(1)} {unit}
        </>
      )
    );
  };

  useEffect(() => {
    if (!useFade) return;

    const timer = setInterval(() => {
      setFade(false);

      setTimeout(() => {
        setCurrIdx((prevIndex) => (prevIndex + 1) % dispatch?.length || 0);
        setFade(true);
      }, 500);
    }, interval);

    return () => clearInterval(timer);
  }, [useFade, dispatch, interval]);

  useEffect(() => {
    setDispatchLength(dispatch?.length || 0);
    setUseFade(dispatch?.length > 1 || false);
  }, [dispatch]);

  return (
    <>
      {!dispatchLength && whereToBuy()}

      {dispatchLength > 0 && (
        <>
          <div className='min-[360px] inline-flex w-full max-w-max items-center rounded-full bg-primary pb-0.5 pl-1 pr-5 pt-0.5 text-base text-white transition duration-150 max-[389px]:hidden md:pr-6'>
            {whereToBuy()}

            <div
              className={`pl-3 font-bold transition-opacity duration-500 ease-in-out md:mt-0.5 ${
                fade ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <div className='text-left'>
                {FormatValue.truncateMiddle(
                  dispatch?.[currIdx]?.attributes?.title || '',
                  20
                )}
              </div>
              <div>
                <span className='font-light'>Installing Dealer</span>{' '}
                {getDistance(currIdx)}
              </div>
            </div>
          </div>

          <div className='hidden max-[389px]:block'>{whereToBuy()}</div>
        </>
      )}
    </>
  );
};

export default WhereToBuyBtnEnhanced;
