export default class FormatValue {
  private static dayFullNameMap: Record<string, string> = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
  };

  static phoneNumber(data: number) {
    const cleaned = ('' + data).replace(/\D/g, ''),
      normalized =
        cleaned.startsWith('1') && cleaned.length === 11
          ? cleaned.slice(1)
          : cleaned,
      match = normalized.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return data;
  }

  static number(data: number, locale?: string) {
    const iso = locale || 'en-us';

    return new Intl.NumberFormat(iso).format(data);
  }

  static date(data: string | number | Date) {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      date = new Date(data);

    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  static groupDays(days: string[]): string[][] {
    const dayOrder = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const sortedDays = days.sort(
      (a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b)
    );

    const ranges: string[][] = [];
    let tempRange: string[] = [];

    sortedDays.forEach((day, i) => {
      if (
        tempRange.length === 0 ||
        dayOrder.indexOf(day) ===
          dayOrder.indexOf(tempRange[tempRange.length - 1]) + 1
      ) {
        tempRange.push(day);
      } else {
        ranges.push(tempRange);
        tempRange = [day];
      }
    });

    if (tempRange.length) ranges.push(tempRange);

    return ranges;
  }

  static formatDayRanges(openDays: string[], closedDays: string[]): string {
    const openRanges = this.groupDays(openDays).map((range) =>
      range.length > 1
        ? `${this.dayFullNameMap[range[0]]} - ${this.dayFullNameMap[range[range.length - 1]]}`
        : this.dayFullNameMap[range[0]]
    );

    const closedRanges = this.groupDays(closedDays).map((range) =>
      range.length > 1
        ? `${this.dayFullNameMap[range[0]]} - ${this.dayFullNameMap[range[range.length - 1]]}`
        : this.dayFullNameMap[range[0]]
    );

    const openString = openRanges.join(', ');
    const closedString =
      closedRanges.length > 0 ? ` (Closed: ${closedRanges.join(', ')})` : '';

    return `${openString}${closedString}`;
  }

  static removeUrlProtocol(url: string) {
    return url.replace(/https?:\/\//, '');
  }

  static truncateMiddle(str: string, maxLength: number): string {
    if (str.length <= maxLength) {
      return str;
    }
    const ellipsis = '...';
    const charsToShow = maxLength - ellipsis.length;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);
    return (
      str.substr(0, frontChars) + ellipsis + str.substr(str.length - backChars)
    );
  }

  static capitalizeFirstLetterOfEachWord(str: string) {
    if (!str) {
      return '';
    }
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => {
        if (!word) return '';
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  static celciusToFahrenheit(celcius: number) {
    return (celcius * 9) / 5 + 32;
  }

  static fahrenheitToCelcius(fahrenheit: number) {
    return ((fahrenheit - 32) * 5) / 9;
  }

  static optimizeImageUrl(url: string): string {
    const vNumber = /\/v\d+\//,
      fileExt = /\.[^/.]+$/;

    return url && url.match(vNumber)
      ? url.replace(vNumber, '/q_auto/f_auto/').replace(fileExt, '')
      : url;
  }
}
